import { IS_VALID_PROSPECT_ID, TRADE_SHIFT_DOMAIN_NAME } from '../constant';

export const formatDateTime = (milliseconds) => {
    const date = new Date(milliseconds);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');

    return `${year}${month}${day} ${hours}:${minutes}:${seconds}`;
};

export const getUKTime = (millisecond) => {
    let date = new Date();
    if (millisecond) {
        date = new Date(millisecond);
    };
    const formatter = date.toLocaleString('en-US', { timeZone: 'Europe/London' });
    return formatter.replace(',', '');
};

export const loadMeta = () => {
    var meta = document.createElement('meta');
    meta.setAttribute('name', 'robots');
    meta.setAttribute('content', 'noindex,nofollow');
    document.head.appendChild(meta);
};

export const deleteMeta = () => {
    var meta = document.querySelector('meta[name="robots"]');
    if (meta) {
        meta.parentNode.removeChild(meta);
    };
};

export const getHashValue = () => {
    let value = '';
    if (window.location && window.location.hash
        && window.location.hash.indexOf('?') !== -1) {
        const searchVal = window.location.hash.slice(
            window.location.hash.indexOf('?') + 1);
        value = searchVal.split('=')[1]
    };
    return value;
};

export const getHashValueByHistory = () => {
    let value = '';
    if (window.location && window.location.search
        && window.location.search.indexOf('?') !== -1) {
        const searchVal = window.location.search.slice(
            window.location.search.indexOf('?') + 1);
        value = searchVal.split('=')[1]
    };
    return value;
};

export const isNotToErrorPageFlag = () => {
    let isShow = true;
    if (IS_VALID_PROSPECT_ID) {
        sessionStorage.setItem('referrer', document.referrer);
        if (document.referrer) {
            if (document.referrer.indexOf(TRADE_SHIFT_DOMAIN_NAME) === -1) {
                isShow = false;
            };
        };

        if (window.location.search != '' && window.location.search != null) {
            const searchVal = window.location.search.slice(window.location.
                search.indexOf('?') + 1);
            if (searchVal.startsWith('n')
                && searchVal.split('=')[0] == 'n' && searchVal.length == 20) {
                    isShow = true;
            } else {
                isShow = false;
            }
        } else {
            isShow = false;
        };
        return isShow;
    } else {
        return true;
    }
}