import React from 'react';
import ReactDOM from 'react-dom/client';
import Routers from "./routers";


ReactDOM.createRoot(document.getElementById("root"))
    .render(<Routers />);

if (module.hot) {
    module.hot.accept();
};
