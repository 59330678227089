import React, { useRef, useState, useEffect } from 'react';
import ValidFailedError from '../../components/validFailedError'

import Cash_title from '../../assets/tradeShift_cash_title.svg';
import phone from '../../assets/phone.jpg';
import top_arrow from '../../assets/top_arrow.svg';
import time_check from '../../assets/time_check.svg'

import { REQUIRED_SELECT_ERROR_MSG } from '../../constant';

export default function topSection(props) {
    const { showError, setShowError } = props;
    const [showDrop, setShowDrop] = useState(false);
    const [morning, setMorning] = useState('Please select');
    const [afternoon, setAfternoon] = useState('');
    const [morningIcon, setMorningIcon] = useState(false);
    const [afternoonIcon, setAfternoonIcon] = useState(false);
    const ulRef = useRef(null);
    const showValue = morning + ';' + afternoon;
    let isClick = false;

    const handleSelect = () => {
        setShowDrop(!showDrop);
    }
    const handleMorningIcon = () => {
        setMorningIcon(!morningIcon);
        !morningIcon ? setMorning('Morning') : setMorning('');
    }
    const handleAfternoonIcon = () => {
        setAfternoonIcon(!afternoonIcon);
        !afternoonIcon ? setAfternoon('Afternoon') : setAfternoon('');
        morning === 'Please select' ? setMorning('') : null;
    }

    const validCheckbox = () => {
        if (!document.getElementById('timeInput').value
            || document.getElementById('timeInput').value === ';'
            || document.getElementById('timeInput').value === 'Please select') {
            setShowError(true);
            setMorning('Please select');
        } else {
            setShowError(false);
        }
    }

    window.addEventListener('click', (event) => {
        var reg = `check-img f-checkbox option-item Morning Afternoon input input-select select-icon input input-select error-input time-select-left-line`;
        if (!isClick && showDrop && reg.indexOf(event.target.className) === -1) {
            isClick = true;
            setShowDrop(false);
            console.log(document.getElementById('timeInput').value);
            if (!document.getElementById('timeInput').value
                || document.getElementById('timeInput').value === ';'
                || document.getElementById('timeInput').value === 'Please select') {
                setShowError(true);
                setMorning('Please select');
            } else {
                setShowError(false);
            }
        };
    });


    return (
        <div className="t-input">
            <label htmlFor="timeInput">Preferred call back time *</label>
            <div className="ttr-select">
                <div className="input-area">
                    <input id='timeInput' name='timeInput'
                        className={`input input-select ${showError ? 'error-input' : ''}`}
                        value={
                            showValue.startsWith(';') ? showValue.slice(1,) : showValue.endsWith(';') ?
                                showValue.slice(0, showValue.length - 1) :
                                showValue.indexOf('Please select') != -1 ?
                                    showValue.replace('Please select;', '') : showValue
                        }
                        onClick={handleSelect}
                        readOnly />
                    <i id='timeIcon' className="select-icon" onClick={handleSelect}></i>
                    {showError ? <ValidFailedError errorMsg={REQUIRED_SELECT_ERROR_MSG}>
                    </ValidFailedError> : null}
                    {showDrop ? <ul className='option-list' id="timeUl">
                        <li className='option-item' onClick={handleMorningIcon}>
                            <label className="time_checkbox">
                                <span className="f-checkbox">
                                    {morningIcon ? <span ref={ulRef} className="morning_check check">
                                        <img src={time_check} alt="" className="check-img" />
                                    </span> : null}
                                </span>
                            </label>
                            <span className="Morning">Morning (9:00am to 12:00pm)</span>
                        </li>
                        <li className='option-item'
                            onClick={handleAfternoonIcon}>
                            <label className="time_checkbox">
                                <span className="f-checkbox">
                                    {afternoonIcon ? <span className="afternoon_check check">
                                        <img src={time_check} alt="" className="check-img" />
                                    </span> : null}
                                </span>
                            </label>
                            <span className="Afternoon">Afternoon (12:00pm to 5:00pm)</span>
                        </li>
                    </ul> : null}
                </div>
                <div onClick={handleSelect} className="time-select-left-line"></div>
            </div>
        </div>
    );
}
