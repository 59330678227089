import React from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';
import Cash_title from '../../assets/tradeShift_cash_title_success.svg';
import Header from '../../components/header';
import { getHashValueByHistory } from '../../util'

export default function topSection() {
	const navigate = useNavigate();

	const backToHome = () => {
		const searchVal = getHashValueByHistory();
		if (searchVal) {
			navigate(`/uk/index.html?n=${searchVal}`, { replace: true });
		} else {
			navigate(`/uk/index.html`, { replace: true });
		};
	};

	return (
		<>
			<Header></Header>
			<div className="ttr-container">
				<div className="t-success">
					<img src={Cash_title} alt="" />
					<div className="title-div">
						<h1 className="t-call">Your call back request has been submitted</h1>
						<div className="text">Thank you for you interest in Tradeshift Cash - together with HSBC. HSBC will get
							in
							touch shortly.</div><button className="ttr-btn back-to-index" onClick={backToHome}>Back</button>
					</div>
				</div>
			</div>
		</>
	);
}
