import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IS_VALID_PROSPECT_ID, TRADE_SHIFT_DOMAIN_NAME } from '../constant';

function AuthRoute({ children }) {
    const navigate = useNavigate();
    useEffect(() => {
        if (IS_VALID_PROSPECT_ID) {
            sessionStorage.setItem('referrer', document.referrer);
            if (document.referrer) {
                if (document.referrer.indexOf(TRADE_SHIFT_DOMAIN_NAME) === -1) {
                    window.location.href = '/error.html';
                    return;
                };
            };
            
            if (window.location.search != '' && window.location.search != null) {
                const searchVal = window.location.search.slice(window.location.search.indexOf('?') + 1);
                if (searchVal.startsWith('n')
                    && searchVal.split('=')[0] == 'n' && searchVal.length == 20) {
                } else {
                    window.location.href = '/error.html';
                }
            } else {
                window.location.href = '/error.html';
            };
        };
    }, []);

    return children;
};

export default AuthRoute;
