import React from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/header';
import { getHashValueByHistory } from '../../util'

export default function topSection() {
	const navigate = useNavigate();
	
	const backToHome = () => {
		const searchVal = getHashValueByHistory();
		if (searchVal) {
			navigate(`/uk/index.html?n=${searchVal}`, { replace: true });
		} else {
			navigate(`/uk/index.html`, { replace: true });
		}
	};

	return (
		<div id="error">
			<Header isHideBtn={true}></Header>
			<div className="ttr-container error-container">
				<div className="error-content">
					<div className="title">Sorry, we can't find the page you're looking for.</div>
					<div className="error-404">Error code: 404</div>
					{/* <div className="detail">It may have expired, or there could be a typo in the link.
						 See if you can find what you need from our homepage.</div>
					</div> */}
					{/* <button className="ttr-btn error-btn" onClick={backToHome}>Visit the homepage</button> */}
				</div>
			</div>
		</div>
	);
}
