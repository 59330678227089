import React, { useRef, useState } from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';
import ValidFailedError from '../../components/validFailedError'
import { REQUIRED_SELECT_ERROR_MSG } from '../../constant';

export default function Select(props) {
    const { forLabel, label, showError, setShowError,
        refValue, options, setRef } = props;

    const validRequiredVal = (e) => {
        const val = (e && e.target && e.target.value
            && e.target.value === 'Please select');
        setShowError(val);
    }

    return (
        <div className="t-input">
            <label htmlFor={forLabel}>{label}</label>
            <div className="ttr-select" id="ttr-select">
                <div className="input-area">
                    <select value={refValue} id={forLabel} onBlur={validRequiredVal}
                        onChange={(e) => { setRef(e.target.value) }} className={`${showError ? 'error-input' : ''}`}>
                        {
                            options.map(item => {
                                return <option value={item} key={item}>{item}</option>
                            })
                        }
                    </select>   
                    {showError ? <ValidFailedError errorMsg={REQUIRED_SELECT_ERROR_MSG}></ValidFailedError> : null}
                </div>
                <div className="select-left-line"></div>
            </div>
        </div>
    );
}
