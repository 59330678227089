import React from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { getHashValueByHistory } from '../../util'

export default function topSection(props) {
    const { isHideBtn } = props;
    const navigate = useNavigate();
    
    const backToHome = () => {
		const searchVal = getHashValueByHistory();
        if (searchVal) {
		    navigate(`/uk/index.html?n=${searchVal}`, { replace: true });
		} else {
			navigate(`/uk/index.html`, { replace: true });
		};
    };

    return (
        <div className="tt-header">
            <div className="header-sed">
                <div className="t-icon"></div><span>HSBC</span>
                {!isHideBtn ? <button className="success-find-out-more button-header"
                    onClick={backToHome}>Find out more</button>
                    :
                    null
                }
            </div>
        </div>
    );
}
