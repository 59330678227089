import React from 'react';

import { BrowserRouter, HashRouter, Routes, Route } from 'react-router-dom';

import Home from '../view/home';
import Submit from '../view/submit';
import Success from '../view/success';
import Error from '../view/error';
import AuthRoute from './auth_route';


function RootRouter(props) {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={<AuthRoute children={<Home />} replace={true} />}
        />
        <Route
          path='/uk'
          element={<AuthRoute children={<Home />} replace={true} />}
        />
        <Route
          path='/uk/index.html'
          element={<AuthRoute children={<Home />} replace={true} />}
        />
        <Route
          path='/uk/submit.html'
          element={<AuthRoute children={<Submit />} replace={true} />}
        />
        <Route
          path='/uk/success.html'
          element={<AuthRoute children={<Success />} replace={true} />}
        />
        <Route path='*' element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}


export default RootRouter;
